<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2" v-for="(item, index) in dataJadwalSidangPanel" :key="item.id">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 mt-2"
        >
          <label>Nomor Register</label>
          <b-form-input
              :id="`no_register-${item.id}`"
              autofocus
              trim
              placeholder="No Register"
              v-model="item.no_register"
              :disabled="true"
            />
        </b-col>
         <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2 mt-2"
        >
          <label>Tenaga Ahli</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            :options="tenagaAhliOption"
            v-model="item.tenaga_ahli_id"

          />
        </b-col>
       <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2 mt-2"
        >
          <label>Penyaji</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            :options="penyajiOption"
            v-model="item.penyaji_id"

          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 mt-3"
        >
          <b-button class="btn" variant="outline-danger" @click="hapusNomorKerjasama(item.id)"><feather-icon icon="TrashIcon" /> </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2 mt-2"
        >
          <label>Ketua Majelis</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            :options="komisionerOption"
            v-model="dataDetailJadwalPanel.ketua_majelis_id"
            
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2 mt-2"
        >
          <label>Anggota Majelis</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            :options="komisionerOption"
            v-model="dataDetailJadwalPanel.anggota_majelis_id"
            multiple
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Tanggal</label>
            <b-form-datepicker id="tanggal" locale="id" v-model="dataDetailJadwalPanel.tanggal" ></b-form-datepicker>
            <small class="mt-1" style="color: red;" v-if="error.tanggal">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Tempat</label>
           <b-form-input
              id="hari"
              autofocus
              trim
              placeholder="Tempat"
              v-model="dataDetailJadwalPanel.tempat"
              
            />
            <small class="mt-1" style="color: red;" v-if="error.kota_id">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Waktu</label>
             <b-form-timepicker locale="id" v-model="dataDetailJadwalPanel.waktu" ></b-form-timepicker>
            <small class="mt-1" style="color: red;" v-if="error.kota_id">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Keterangan Tambahan Wakil Ketua KY</label>
             <b-form-textarea rows="3" v-model="dataDetailJadwalPanel.keterangan" ></b-form-textarea>
            <small class="mt-1" style="color: red;" v-if="error.Keterangan">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <b-button class="btn" variant="primary" @click="simpanData">Simpan</b-button>
          <b-button class="btn ml-1" v-b-modal.modal_confirm_pemeriksaan_lanjutan_4 variant="danger">Batalkan Jadwal Sidang</b-button>

          <b-modal id="modal_confirm_pemeriksaan_lanjutan_4" title="Konfirmasi" centered hide-footer>
            <label for="">Alasan Dibatalkan</label>
            <div class="d-flex justify-content-center" >
              <b-form-textarea rows="3" v-model="dataDetailJadwalPanel.alasan_batal" ></b-form-textarea>
            </div>
            <div class="d-flex justify-content-end mt-2">
              <b-form-group>
                <b-button class="btn mr-1" variant="primary" @click="batalSidang()">Ya</b-button>
              </b-form-group>
              <b-form-group>
                <b-button class="btn" variant="outline-primary" ref="btnClose" @click="$root.$emit('bv::hide::modal', 'modal_confirm_pemeriksaan_lanjutan_4')">Tidak</b-button>
              </b-form-group>
            </div>
          </b-modal>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker, BFormTimepicker, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  uploadFileLogo,
} from '@/connection-api/instansi'
import {
  updateDataPanelJadwal,
  batalJadwalPanel,
  hapusNomorKerjasamaJadwalPanel,
} from '@/connection-api/master'
import PersidanganMonitoring from '@/views/persidangan/monitoring/list/List.vue'
import TimMajelis from '@/views/tim-majelis/list/List.vue'
import TimPanel from '@/views/tim-panel/list/List.vue'
import TenagaAhli from '@/views/tenaga-ahli/list/List.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    BFormTimepicker,
    BModal,
    PersidanganMonitoring,
    TimMajelis,
    TimPanel,
    TenagaAhli,
  },
  data() {
    return {
      testing: [1, 2],
      error: {},
      refresh: 0,
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
        tenaga_ahli_id: null,
        tim_panel_id: null,
      },
      komisionerOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Nama --' },
          { value: 1, label: '-- a --' },
          { value: 2, label: '-- b --' },
        ],
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    pihak: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    timAhliOptions: {},
    timPanelOptions: {},
    selectedCheckbox: {},
    dataJadwalSidangPanel: {},
    tenagaAhliOption: {},
    penyajiOption: {},
    timPemeriksaOption: {},
    komisionerOption: {},
    dataDetailJadwalPanel: {},
  },
  async mounted() {
    // console.log(this.selectedCheckbox)
  },
  methods: {
    async hapusNomorKerjasama(laporan_id) {
      var data = {
        laporan_id: laporan_id,
        jadwal_panel_id: this.$route.params.id
      }

      const response = await hapusNomorKerjasamaJadwalPanel(data)
      if(response.data.success === true)
      {
        this.$emit('getDataPanel')
      }
    },

    getDataLaporan(id,no_register)
    {
      this.localData.laporan_id = id
      this.localData.no_register = no_register
      this.refresh += 1
    },
     getDataTimMajelis(id,nama)
    {
      this.localData.tim_majelis_id = id
      this.localData.tim_majelis = nama
      this.refresh += 1
    },
     getDataTimPanel(id,nama)
    {
      this.localData.tim_panel_id = id
      this.localData.tim_panel = nama
      this.refresh += 1
    },
     getDataTenagaAhli(id,nama)
    {
      this.localData.tenaga_ahli_id = id
      this.localData.tenaga_ahli = nama
      this.refresh += 1
    },
    async uploadLogo(id)
    {
      if(this.localData.file !== null)
      {
        const responseUpload = await uploadFileLogo(id, this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
      }
    },
    async batalSidang() {
      const response = await batalJadwalPanel(this.dataDetailJadwalPanel)
      if(response.data.success === true)
      {
        this.$router.push({name: 'panel-jadwal'})
      }
    }, 
    async simpanData()
    {
      this.localData.dataDetailJadwalPanel = this.dataDetailJadwalPanel
      this.localData.dataJadwalSidangPanel = this.dataJadwalSidangPanel
      const response = await updateDataPanelJadwal(this.localData)

      if(response.data.success === true)
      {
        this.uploadLogo(response.data.id)
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Jadwal Panel',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.localData.nama_negara = null
          this.localData.kode_negara = null
          this.$emit('refetchData')
          this.$root.$emit('bv::hide::modal', 'buat_panel_jadwal')
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Jadwal Panel',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          // this.error = response.data.error
          this.$emit('refetchData')
          this.$root.$emit('bv::hide::modal', 'buat_panel_jadwal')
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
