<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Lanjut"
      back-button-text="Kembali"
      next-button-text="Lanjut"
      class="mb-3"
    >
      <tab-content
        title="Jadwal Sidang"
      >
      <b-row>
        <b-col cols="12">
          <div class="ml-2 mb-2">
              <b-button class="btn" variant="primary" @click="openModal"><feather-icon icon="PlusIcon" /> Nomor Register </b-button>
              <b-modal id="tambah_nomor_register" size="xl" title="Jadwal Panel" ref="tambah_nomor_register" hide-footer>
                <list-daftar-tunggu-panel @getDataPanel="emitGetDataPanel"/>
              </b-modal>
          </div>
          <jadwal-sidang 
          :dataJadwalSidangPanel="localData.dataJadwalSidangPanel"
          :dataDetailJadwalPanel="localData"
          :tenagaAhliOption="tenagaAhliOption"
          :penyajiOption="penyajiOption"
          :timPemeriksaOption="timPemeriksaOption"
          :komisionerOption="komisionerOption"
          @getDataPanel="emitGetDataPanel"
          />
        </b-col>
      </b-row>
      </tab-content>

      <tab-content
            title="Penetapan"
          >
          <b-row>
            <b-col cols="12">
              <penetapan  :localData="localData" :jenisDokumenOptions="jenisDokumenBAOptions.options"/>
            </b-col>
          </b-row>
      </tab-content>

      <tab-content
        title="Putusan"
      >
      <b-row>
        <b-col cols="12">
           <tindakan 
           :penetapanOptions="penetapanOptions.options"
           :komisionerOptions="komisionerOptions.options"
           :localData="localData.tindakan"
           :localDatas="localData"
           :dataPutusan="dataPutusan"
           />
        </b-col>
      </b-row>
      </tab-content>

      <tab-content
          title="Berita Acara"
      >
        <berita-acara  :localData="localData" :jenisDokumenOptions="jenisDokumenBAOptions.options"/>
      </tab-content>

      <tab-content
        title="Pengesahan Hasil Sidang"
      >
      <b-row>
        <b-col cols="9">
          <pengesahan-hasil-sidang-lpp :localData="localData" :jenisDokumenOptions="jenisDokumenOptions.options" />
        </b-col>
      </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormDatepicker,
} from 'bootstrap-vue'
import {
  updateTplPemeriksaanLanjutan,
  getPenetapan,
  getKomisioner,
  getJenisDokumen,
  getDataBuatJadwalSidangPanel,
} from '@/connection-api/master'
import { required, email } from '@validations'
import { codeIcon } from './code'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import JadwalSidang from ".././list/JadwalSidang.vue"
import PengesahanHasilSidangLpp from './component/PengesahanHasilSidangLpp.vue'
import BeritaAcara from './component/BeritaAcara.vue'
import Penetapan from './component/Penetapan.vue'
import Tindakan from './component/Tindakan.vue'
import ListDaftarTungguPanel from '@/views/persidangan/panel-daftar-tunggu/list/ListModal.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    BFormDatepicker,
    JadwalSidang,
    PengesahanHasilSidangLpp,
    BeritaAcara,
    Tindakan,
    Penetapan,
    ListDaftarTungguPanel,
  },
  async mounted() {
    const data = {
      jenis_sidang: 'panel',
    }
     const reponsePenetapan = await getPenetapan(data)
     this.mappingSelectOption(reponsePenetapan.data, this.penetapanOptions.options)

      const responseKomisioner = await getKomisioner()
     this.mappingSelectOption(responseKomisioner.data, this.komisionerOptions.options)

     const params = {
        dokumen_id: 21,
      }
      const jenisDokumen21 = await getJenisDokumen(params)
      this.mappingSelectOption(jenisDokumen21.data, this.jenisDokumenOptions.options)

      const params2 = {
        dokumen_id: 18,
      }
      const jenisDokumen18 = await getJenisDokumen(params2)
      this.mappingSelectOption(jenisDokumen18.data, this.jenisDokumenBAOptions.options)
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      centeredModalVisible: false,
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      pemeriksaanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Pemeriksaan --' },
          { value: 'Konfrontir', label: 'Konfrontir' },
          { value: 'Terlapor', label: 'Terlapor' },
          { value: 'Pelapor/Saksi/Ahli', label: 'Pelapor/Saksi/Ahli' },  
        ],
      },
      penetapanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Penetapan --' },
        ],
      },
      komisionerOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Penanggung Jawab --' },
        ],
      },
      jenisDokumenOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
      jenisDokumenBAOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
    }
  },
  props: {
    jenisPenyampaianOptions: {},
    kantorPenghubungOptions: {},
    jenisPerkaraOptions: {},
    lingkunganPeradilanOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    jenisPihakTerlibatOptions: {},
    localData: {},
    tenagaAhliOption: {},
    penyajiOption: {},
    timPemeriksaOption: {},
    komisionerOption: {},
    dataPutusan: {},
  },
  methods: {
    emitGetDataPanel() {
      this.$emit('getDataPanel', this.$route.params.id)
    },
    openModal() {
      this.$refs.tambah_nomor_register.show();
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    async updateBapTerlapor()
    {
      this.localData.bap_terlapor.mark = 'bap_terlapor'
      const responseBapTerlapor = await updateTplPemeriksaanLanjutan(this.localData.bap_terlapor)
      this.localData.bap_terlapor = responseBapTerlapor.data.data
    },
    formSubmitted() {
    },
    bapTerlapor() {
      return new Promise((resolve, reject) => {
        this.$refs.bapTerlaporRules.validate().then(success => {
           if (success) {
              this.updateBapTerlapor()
              resolve(true)
            } else {
              reject()
            }
        })
      })
    },
    pemeriksaanLanjutan() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
             if (success) {
                resolve(true)
              } else {
                reject()
              }
          }
        })
      })
    },
     makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  },
}
</script>
