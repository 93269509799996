 <style>
    .custom-control {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 3.4499999999999993rem;
        color-adjust: exact;
    }
  </style>

<template>
  <b-row class="mb-2">
    <b-col
      cols="12"
      md="12"
      class="mb-md-0 mt-4"
    >
      <b-card no-body>
        <b-tabs content-class="mt-3">
          <b-tab v-for="(putusan, index) in dataPutusan" :key="putusan.id" :title="putusan.no_register">
            <b-card-body>
              <b-row class="mb-2">
                <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0 mt-2"
                >
                  <label>Putusan Berdasarkan Nomor Register</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      class="w-100"
                      :reduce="val => val.value"
                      :options="penetapanOptions"
                      v-model="putusan.putusan_id"
                    />
                    <small class="mt-1" style="color: red;" v-if="error.putusan_id">{{error.message}}</small>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0 mt-4"
                >
                <div class="d-flex" v-if="putusan.putusan_id === 2 && putusan.putusan_id !== 4">
                  <label for="">Kesimpulan</label>
                  <b-form-checkbox-group
                      v-model="putusan.kesimpulan"
                      :options="optionsBTD"
                      class="mb-3 ml-3"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      stacked
                    ></b-form-checkbox-group>
                </div>
                <div class="d-flex" v-if="putusan.putusan_id === 1 && putusan.putusan_id !== 4">
                  <label for="">Kesimpulan</label>
                  <b-form-checkbox-group
                      v-model="putusan.kesimpulan"
                      :options="optionsBD"
                      class="mb-3 ml-3"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      stacked
                    ></b-form-checkbox-group>
                </div>
                    <small class="mt-1" style="color: red;" v-if="error.tim_panel">{{error.message}}</small>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0"
                  v-if="putusan.putusan_id !== 3 && putusan.putusan_id !== 4"
                >
                  <label>Penanggung Jawab Penanganan Laporan Anggota KY</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      class="w-100"
                      :reduce="val => val.value"
                      :options="komisionerOptions"
                      v-model="putusan.penanggung_jawab_id"
                    />
                    <small class="mt-1" style="color: red;" v-if="error.penanggung_jawab_id">{{error.message}}</small>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0 mt-2"
                  v-if="putusan.putusan_id !== 3 && putusan.putusan_id !== 4"
                >
                  <label>Uraian Putusan Berdasarkan Pokok Laporan</label>
                    <b-form-textarea min-rows="3" v-model="putusan.uraian_putusan"></b-form-textarea>
                    <small class="mt-1" style="color: red;" v-if="error.tim_panel">{{error.message}}</small>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0 mt-2"
                  v-if="putusan.putusan_id !== 3 && putusan.putusan_id !== 4"
                >
                  <label>Pendapat Majelis</label>
                    <b-form-textarea min-rows="3" v-model="putusan.pendapat_majelis"></b-form-textarea>
                    <small class="mt-1" style="color: red;" v-if="error.tim_panel">{{error.message}}</small>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0 mt-2"
                  v-if="putusan.putusan_id === 4"
                >
                  <label>Alasan</label>
                    <b-form-textarea min-rows="3" v-model="putusan.alasan"></b-form-textarea>
                    <small class="mt-1" style="color: red;" v-if="error.alasan">{{error.message}}</small>
                </b-col>
                
                <!-- <b-col
                  cols="12"
                  md="12"
                  class="mt-2"
                  v-if="putusan.putusan_id !== 3 && putusan.putusan_id !== 4"
                >
                    <b-media no-body>
                      <b-media-body>
                        <label>Upload Tanda Tangan Ketua</label>
                        <div class="d-flex" style="width: 100%;">
                          <b-form-file
                            placeholder="Masukkan File"
                            class="mr-1"
                            v-model="putusan.file"
                          >
                          </b-form-file>
                          <b-button class="btn mr-1" variant="outline-success" v-if="putusan.tanda_tangan != null" @click="download(putusan.tanda_tangan)">Download</b-button>
                        </div>
                        <label class="ml-1" style="color: red;" >Ukuran file max 5mb</label>
                      </b-media-body>
                    </b-media>
                </b-col> -->
                <b-col
                  cols="12"
                  md="12"
                  class="mt-2"
                >
                <div>
                    <b-modal id="modal_confirm_pemeriksaan_lanjutan_3" title="Konfirmasi" centered hide-footer>
                      <div class="d-flex justify-content-center" >
                        <h4 class="my-4" style="text-align: center;">Apakah anda yakin teruskan ke Pemeriksaan Lanjutan?</h4>
                      </div>
                      <div class="d-flex justify-content-end">
                        <b-form-group>
                          <b-button class="btn mr-1" variant="primary" @click="update('pemeriksaan-lanjutkan')">Ya</b-button>
                        </b-form-group>
                        <b-form-group>
                          <b-button class="btn" variant="outline-primary" ref="btnClose" @click="$root.$emit('bv::hide::modal', 'modal_confirm_pemeriksaan_lanjutan_3')">Tidak</b-button>
                        </b-form-group>
                      </div>
                    </b-modal>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0 mt-2"
                >
                <div class="d-flex justify-content-begin mb-3 ml-2">
                    <b-button class="btn" variant="primary" @click="$bvModal.show(`modal_konfirmasi_no_registrasi_tindakan_${index}`)">Simpan</b-button>
                </div>
                <div>
                  <b-modal :id="`modal_konfirmasi_no_registrasi_tindakan_${index}`" centered title="Konfirmasi">
                    <center>
                      <h4 class="my-2">Apakah anda yakin ingin akan melakukan putusan?</h4>
                    </center>
                    <template #modal-footer>
                      <b-button variant="primary" @click="updateTindakan(index)">Ya</b-button>
                      <b-button variant="secondary" @click="$bvModal.hide(`modal_konfirmasi_no_registrasi_tindakan_${index}`)">Tidak</b-button>
                    </template>
                  </b-modal>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="12"
                class="mb-md-0 mt-2"
              >
              <!-- <div class="d-flex justify-content-begin mb-3 ml-2">
                  <b-button class="btn" variant="primary" @click="$bvModal.show(`modal_konfirmasi_redirect`)">Simpan</b-button>
              </div> -->
              <div>
                <b-modal :id="`modal_konfirmasi_redirect_${index}`" centered title="Konfirmasi">
                  <center>
                    <h4 class="my-2">Apakah anda ingin tetap dihalaman ini?</h4>
                  </center>
                  <template #modal-footer>
                    <b-button variant="primary" @click="$bvModal.hide(`modal_konfirmasi_redirect_${index}`)">Ya</b-button>
                    <b-button variant="secondary" @click="redirectF(putusan.putusan_id)">Tidak</b-button>
                  </template>
                </b-modal>
              </div>
            </b-col>
              </b-row>
            </b-card-body>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker, BFormTimepicker, BFormCheckboxGroup, BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    updateJadwalPanelPutusan,
    uploadFilePanelJadwal,
    downloadFilePanelJadwal,
    updateVerifikasi,
} from '@/connection-api/master'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    BFormTimepicker,
    BFormCheckboxGroup,
    BTab,
    BTabs,
  },
  data() {
    return {
      error: {},
      selected: [],
        optionsBTD: [
          { item: 'Tidak Terapat Pelanggaran KEPPPH', name: 'Tidak Terapat Pelanggaran KEPPPH' },
          { item: 'Bukan Wewenang Komisi Yudisial', name: 'Bukan Wewenang Komisi Yudisial' },
          { item: 'Tidak Terdapat Cukup Bukti', name: 'Tidak Terdapat Cukup Bukti' },
          { item: 'Diajukan Dalam Sidang Pleno', name: 'Diajukan Dalam Sidang Pleno' },
        ],
        optionsBD: [
          { item: 'Berkas Diprioritaskan', name: 'Berkas Diprioritaskan' },
          { item: 'Melakukan Pemeriksaan Terhadap Ahli', name: 'Melakukan Pemeriksaan Terhadap Ahli' },
          { item: 'Melakukan Pemeriksaan Terhadap Terlapor', name: 'Melakukan Pemeriksaan Terhadap Terlapor' },
          { item: 'Meminta Klarifikasi Terhadap Terlapor', name: 'Meminta Klarifikasi Terhadap Terlapor' },
          { item: 'Melakukan Konfrontasi Terhadap Pelapor', name: 'Melakukan Konfrontasi Terhadap Pelapor' },
          { item: 'Melakukan Konfrontasi Terhadap Saksi', name: 'Melakukan Konfrontasi Terhadap Saksi' },
          { item: 'Melakukan Konfrontasi Terhadap Ahli', name: 'Melakukan Konfrontasi Terhadap Ahli' },
          { item: 'Melakukan Konfrontasi Terhadap Terlapor', name: 'Melakukan Konfrontasi Terhadap Terlapor' },
        ]
    }
  },
  directives: {
    Ripple,
  },
  props: {
    pihak: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    penetapanOptions: {},
    komisionerOptions: {},
    localData: {},
    localDatas: {},
    dataPutusan: {},
  },
  methods: {
    async redirectF(putusan_id) {
      switch (putusan_id) {
      case 5:
        this.$router.push('/tpl-pemeriksaan-lanjutan');
        break;

      case 6:
      this.$router.push('/tpl-pemeriksaan-lanjutan');
        break;

      case 7:
        this.$router.push('/tpl-pemeriksaan-pendahuluan');
        break;
      
      case 8:
        this.$router.push('/tpl-pemeriksaan-pendahuluan');
        break;

      case 9:
      this.$router.push('/tpl-pemeriksaan-lanjutan');
        break;
    }

    },
    async update(params) {
      switch (params) {
        case 'pemeriksaan-lanjutan':
          this.dataPutusan.jenis_cta = "pemeriksaan-lanjutan";
          break;
      }
      
      const response = await updateVerifikasi(this.localData)
      if(response.data.success === true)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Panel Jadwal',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$router.push({name: 'panel-jadwal'})
          }
          else
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Panel Jadwal',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.error
          }
    },
    async download(filename){
      const result = await downloadFilePanelJadwal(filename)
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      const date = new Date().toLocaleString(['ban', 'id'])
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    },
    async updateTindakan(index)
    {
      if(this.dataPutusan[index].file !== undefined)
        {
          const responseUpload = await uploadFilePanelJadwal(this.dataPutusan[index].file, event => {
            this.progress = Math.round((100 * event.loaded) / event.total)
          })
          this.dataPutusan[index].tanda_tangan = responseUpload.data.filename
        }
  
        const response = await updateJadwalPanelPutusan(this.dataPutusan[index])
        console.log(response)
        if(response.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Tindakan',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: this.dataPutusan[index].no_register + ' ' + response.data.message,
              },
            })
            this.$bvModal.hide(`modal_konfirmasi_no_registrasi_tindakan_${index}`)
            switch (this.dataPutusan[index].putusan_id) {
              case 1:
                this.$bvModal.show(`modal_konfirmasi_redirect_${index}`)
                break;

              case 2:
                this.$bvModal.show(`modal_konfirmasi_redirect_${index}`)
                break;

              case 3:
                this.$bvModal.show(`modal_konfirmasi_redirect_${index}`)
                break;

              case 10:
                this.$bvModal.show(`modal_konfirmasi_redirect_${index}`)
                break;
            }
            
        }
        else
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Tindakan',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: this.dataPutusan[index].no_register + ' ' + response.data.message,
              },
            })
            this.error = response.data.error
        }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
